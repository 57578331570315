import './PrivacyPolicySection.css';

import React from 'react';

function TnCSection(props) {
    return (
        <div className='container priv-section'>
<p>Syarat &amp; ketentuan yang ditetapkan di bawah ini mengatur pemakaian jasa yang ditawarkan oleh PT. Ngebid Arunika Indonesia terkait penggunaan Aplikasi Ngebid. Pengguna disarankan membaca dengan seksama karena dapat berdampak kepada hak dan kewajiban Pengguna di bawah hukum.</p>
<p>Dengan mendaftar dan/atau menggunakan aplikasi Ngebid, maka pengguna dianggap telah membaca, mengerti, memahami dan menyutujui semua isi dalam Syarat &amp; ketentuan. Syarat &amp; ketentuan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara Pengguna dengan PT. Ngebid Arunika Indoneisa. Jika pengguna tidak menyetujui salah satu, sebagian, atau seluruh isi Syarat &amp; ketentuan, maka pengguna tidak diperkenankan menggunakan layanan di aplikasi Ngebid.</p>
<p></p>
<p><strong>Informasi Umum:</strong></p>
<p>
<p>Ngebid sebagai sarana penunjang bisnis yang fokus dalam pelelangan dengan menyediakan berbagai fitur layanan untuk menjamin keamanan dan kenyamanan para penggunannya.</p>
<p>Ngebid tidak berperan sebagai pelelang barang, melainkan sebagai perantara antara Bidder dan Pelelang. untuk mengamankan setiap transaksi yang berlangsung di dalam platform Ngebid melalui mekanisme sistem pembayaran Ngebid.</p>
<p>Biaya titip lelang di Ngebid adalah sebesar 4% untuk barang dengan harga akhir lelang &gt; Rp. 250.000 dan biaya sebesar Rp. 10.000 untuk barang dengan harga akhir lelang &lt; Rp. 250.000, biaya tersebut akan otomatis dikurangi dari total yang dibayarkan oleh pemenang lelang sebelum ditransfer ke dalam BidPay (e-wallet) pelelang.</p>
<p>Adanya biaya ekstra (termasuk pajak dan biaya lainnya) atas segala transaksi yang terjadi di Ngebid berada di luar kewenangan Ngebid sebagai perantara, dan akan diurus oleh pihak-pihak yang bersangkutan (baik Pelelang atau pun Bidder sesuai ketentuan yang berlaku di Indonesia.</p>
<p>Ngebid hanya mengizinkan lelang dan jual barang yang dapat dikirim melalui jasa pengiriman (ekspedisi), sehingga jasa tidak dapat dilelangkan melalui Ngebid.</p>
<p>Ngebid hanya mengizinkan barang lelang yang tidak tercantum dalam daftar Barang Terlarang</p>
<p>Ngebid tidak bertanggung jawab atas kualitas barang, proses pengiriman, dan/atau permasalahan yang dapat terjadi antara Pelelang dan Bidder</p>
<p>Sistem pembayaran Ngebid bersifat aman. Dana pembayaran akan dikirimkan kepada Pelelang jika Bidder sudah menerima dan mereview barang sesuai dengan deksripsi yang tertera, dengan begitu transaksi dianggap selesai.</p>
<p>Ngebid berhak meminta data-data pribadi Pengguna jika diperlukan.</p>
<p>Fitur Chat hanya terhubung dengan admin ngebid bukan dengan Pelelang/Pemilik barang. Fitur Chat tersedia pada setiap iklan barang yang berfungsi sebagai bantuan dalam bertransaksi.</p>
<p>Setiap Pengguna melakukan transaksi baik melelang atau mengikuti lelang akan mendapatkan poin yang dapat ditukarkan dengan kupon atau hadiah yang disediakan oeh Ngebid. Poin yang didapatkan akan disimpan dalam fitur BidPoints</p>
<p>Fitur BidPay merupakan E-wallet dari Pengguna Ngebid yang berfungsi dalam proses transaksi.</p>
<p>Ngebid memiliki kewenangan untuk mengambil tindakan yang dianggap perlu terhadap akun yang diduga melanggar aturan penggunaan dan/atau melakukan penyalahgunaan seperti memanipulasi, maka Ngebid akan menghentikan akun Pengguna.</p>
<p>Aturan Penggunaan Ngebid dapat berubah sewaktu-waktu dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan terlebih dahulu. Dengan mengakses Ngebid. Pengguna dianggap menyetujui perubahan-perubahan dalam Aturan Penggunaan Ngebid.&nbsp;</p>
<p>Pengguna wajib mengisi data pribadi secara lengkap dan jujur pada halaman profile akun</p>
<p>Pengguna yang telah memiliki akun dapat menggunakannya untuk membuat lelang (disebut sebagai pelelang) dan juga mengikuti lelang (disebut sebagai Bidder).</p>
<p>Pengguna bertanggung jawab atas keamanan dari akun termasuk penggunaan e-mail dan password.&nbsp;</p>
<p>Pengguna wajib melampirkan data nomor rekening bank untuk keperluan transaksi di Ngebid.</p>
<p>Dengan menggunakan fasilitas yang telah disediakan oleh Ngebid ,mengindikasikan bahwa Pengguna telah memahami dan menyetujui segala aturan yang diberlakukan oleh Ngebid.</p>
<p>Pengguna dilarang keras menggunakan platform Ngebid sebagai sarana jual beli atau pelelangan barang yang melanggar peraturan yang ditetapkan oleh hukum di Indonesia.</p>
<p>Selama Pengguna berada di dalam aplikasi Ngebid, dilarang untuk menyampaikan jenis konten apapun yang mengandung unsur SARA, memfitnah, diskriminasi, menyudutkan pihak lain dan/atau mencemarkan nama baik.</p>
<p>Pengguna bertanggung jawab atas segala risiko yang timbul di kemudian hari atas informasi yang diberikannya ke dalam Ngebid.</p>
<p>Pengguna diwajibkan menghargai hak-hak Pengguna lainnya dengan tidak memberikan informasi pribadi ke pihak lain tanpa izin pihak yang bersangkutan.</p>
<p>Ngebid memiliki hak untuk memblokir penggunaan sistem terhadap Pengguna yang melanggar peraturan perundang-undangan yang berlaku di wilayah Indonesia.</p>
<p>Pelelang harus melelang sesuai dengan prosedur yang telah ditetapkan oleh Ngebid, termasuk dari segi barang maupun dari sistem transaksi lelang.</p>
<p>Pelelang membuat iklan lelang barang dengan menyertakan deskripsi barang secara lengkap, foto dari kondisi barang, minus, serta autentisitas barang yang sesungguhnya tanpa adanya unsur manipulasi.</p>
<p>Pelelang wajib memasukan nomor resi pengiriman barang maksimal 2x24 jam terhitung sejak lelang telah dimenangkan oleh seorang Bidder.</p>
<p>Ngebid berhak dan berkewenangan untuk menahan pembayaran dana di Rekening Resmi Ngebid apabila terjadi permasalahan dari pihak Bidder terkait proses pengiriman maupun kualitas barang yang diterima. Jika pelelang terbukti mengirimkan barang yang tidak sesuai dengan deskripsi, Ngebid berhak untuk mengembalikan dana kepada pihak Bidder yang merupakan pemenang lelang. Ngebid yang akan menentukan untuk mengirimkan pembayaran kepada pelelang hanya apabila permasalahan telah selesai, dan pelelang terbukti tidak bersalah.</p>
<p>Pelelang memahami dan menyetujui bahwa pembayaran atas harga barang dan ongkos kirim akan dikembalikan kepada Bidder yang merupakan pemenang lelang apabila transaksi gagal dan/atau tidak berhasil.</p>
<p>Pelelang yang tidak mengirimkan barang ketika pemenang lelang sudah ditetapkan dalam batas waktu pengiriman sejak pembayaran, akan mendapatkan 1x peringatan dan diberi pertanyaan mengapa barang tidak dikirimkan, jika tindakan pelelang tersebut masih diulangi, Ngebid berhak memblokir akun pelelang.</p>
<p>Pelelang dilarang keras untuk melelang tumbuhan dan hewan peliharaan yang dilindungi/terancam punah sesuai Pasal 21 ayat (2) UU 5/1990. Bidder</p>
<p>Saat terjadi pelelangan, Bidder menyetujui bahwa Bidder bertanggung jawab untuk membaca, memahami, dan menyetujui informasi yang tertera pada kolom deksripsi barang sebelum melakukan Bidding atau memutuskan untuk menggunakan fitur Lelang / Place Bid.</p>
<p>Setelah Bidder berhasil memenangkan suatu lelang, Bidder wajib bertransaksi sesuai dengan prosedur yang telah ditetapkan oleh Ngebid. Bidder melakukan transaksi pembayaran sesuai dengan metode pembayaran yang Bidder pilih, yang nantinya dana tersebut akan masuk ke rekening Ngebid, kemudian Ngebid akan meneruskan dana tersebut ke pihak Pelelang jika barang sudah diterima dan direview positif oleh pemenang lelang, dengan begitu transaksi dianggap telah selesai.</p>
<p>Pelelang wajib mengirimkan barang yang di lelang jika Bidder sudah terkonfirmasi melakukan pembayaran sesuai dengan metode pembayaran yang dipilih.</p>
<p>Pembayaran barang lelang maksimal dibayarkan oleh Bidder dalam batas waktu 1x24 jam sejak lelang dimenangkan. Jika lebih dari waktu yang ditentukan, maka Ngebid berkewenangan untuk membatalkan transaksi tersebut dan masuk ke dalam kategori Bid n Run. Pelelang tidak berhak untuk mengajukan tuntutan atas pembatalan tersebut.</p>
<p>Bidder wajib melakukan pembayaran barang lelang sesuai dengan nominal yang telah disepakati. Ngebid tidak bertanggung jawab jika terjadi ketidaksesuaian dengan jumlah nominal tagihan pembayaran.</p>
<p>Jika terjadi kesalahan atau ketidaksesuaian barang yang diterima oleh Bidder maka Customer Service Ngebid berhak membuat forum / multichat antara Pelelang, Pemenang Lelang dan Ngebid. Dimana ngebid sebagai pihak ketiga memiliki kewenangan untuk menilai, dan memutuskan akhir penyelesaian permasalahan. Keputusan Ngebid adalah keputusan yang tidak bisa diganggu gugat, sehingga membuat Pelelang dan Bidder wajib untuk mematuhi keputusan yang berlaku.</p>
<p>Bidder yang memenangkan lelang wajib memberikan review dan rating setelah barang diterima, setelah pemberian review dan rating berdasarkan beberapa hal diantaranya: kecepatan, ketepatan pengiriman, dan kesesuaian barang dengan foto. Setelah proses pemberian review dan rating maka transaksi lelang dianggap sudah selesai.</p>
<p>Bidder dapat langsung membeli barang lelang tanpa adanya proses lelang dengan menggunakan fitur Buy it Now untuk langsung membeli barang lelang sesuai dengan harga yang Pelelang cantumkan untuk fitur Buy it Now.</p>
<p>Jika terjadi Bid n Run pemenang lelang akan diserahkan pada Bidder tertinggi kedua-dan seterusnya sesuai izin pelelang. Bidder yang melakukan Bid n Run akan diberikan peringatan sebanyak-banyaknya 2X peringatan. Jika Bidder tetap melakukan Bid n Run setelah diberi 2X peringatan, Ngebid berhak memblokir akun dan no. Handphone Bidder tersebut.&nbsp;</p>
</p>
<p>
<p><strong>Barang terlarang:</strong></p>
<p>Narkotika, obat-obatan terlarang.</p>
<p>Minuman berakohol</p>
<p>Mobil/Motor (kecuali yang dapat dikirim dengan ekspedisi yang tersedia dengan berat &lt; 50.000 g)</p>
<p>Hewang langka / dilindungi / terancam punah.</p>
<p>Senjata api, kelengkapan senjata api, replica senjata api, senjata tajam serta senjata lainnya.</p>
<p>Organ manusia.</p>
<p>Informasi pribadi.</p>
<p>Barang yang mudah terbakar atau meledak.</p>
<p>Barang bajakan seperti: DVD, CD, Software dsb.&nbsp;</p>
<p>Barang yang berkaitan dengan pornografi.</p>
<p>Barang non-fisik yang tidak dapat dikirimkan melalui jasa kurir terdaftar/tidak terdaftar</p>
<p>Barang-barang lain yang dilarang untuk diperjualbelikan secara bebas berdasarkan hukum yang berlaku di Indonesia&nbsp;</p>
<p><strong>Pembatasan Tanggung Jawab:&nbsp;</strong></p>
<p>Ngebid tidak bertanggung jawab atas segala resiko dan kerugian yang timbul dari dan dalam kaitannya dengan informasi yang dituliskan oleh Pengguna Ngebid.</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul dari dan dalam kaitannya dengan informasi yang dituliskan oleh Pengguna Ngebid.</p>
<p>Ngebid tidak bertanggung jawab atas segala pelanggaran hak cipta, merek, desain industri, desain tata letak sirkuit, hak paten atau hak-hak pribadi lain yang melekat atas suatu barang, berkenaan dengan segala informasi yang dibuat oleh Pelelalng.&nbsp;</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul berkenaan dengan penggunaan barang yang dibeli melalui aplikasi Ngebid, dalam hal terjadi pelanggaran peraturan perundang-undangan.</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul berkenaan dengan diaksesnya akun Pengguna oleh pihak lain.</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul akibat transaksi di luar sistem pembayaran Ngebid.</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul akibat kesalahan atau perbedaan nominal yang seharusnya ditransfer ke rekening atas nama PT. Ngebid Arunika Indonesia.</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul apabila transaksi telah selesai secara sistem (dana telah masuk ke BidPay ataupun Bidder).</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul akibat kehilangan barang ketika proses transaksi berjalan dan/atau selesai.</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul akibat kesalahan Pengguna ataupun pihak lain dalam transfer dana ke rekening PT. Ngebid Arunika Indonesia.&nbsp;</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul apabila akun dan/atau BidPay dalam keadaan dibekukan dan/atau dinonaktifkan.</p>
<p>Ngebid tidak bertanggung jawab atas segala risiko dan kerugian yang timbul akibat penyalahgunaan nomor kontak dan/atau alamat e-mail milik Pengguna maupun pihak lainnya.</p>
<p>Dalam keadaan apapun, Pengguna akan membayar kerugian Ngebid dan/atau menghindarkan Ngebid (termasuk petugas, direktur, karyawan, agen, dan lainnya) dari setiap biaya kerugian apapun, kehilangan, pengeluaran atau kerusakan yang berasal dari tuntutan atau klaim Pihak ke-tiga yang timbul dari pelanggaran Pengguna terhadap Aturan Penggunaan Ngebid, dan/atau pelanggaran terhadap hak dari pihak ke-tiga.</p>
</p>
</div>
    );
}

export default TnCSection;