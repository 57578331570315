import {Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Priv from "./pages/PrivacyPolicy";
import TermsAndCondtions from "./pages/TermsAndConditions";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Main />} />
      <Route path="/privacy-policy" element={<Priv />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondtions/>}/>
    </Routes>
  );
}

export default App;

