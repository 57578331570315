import Header from "../components/Header";
import FooterSection from "../sections/FooterSection";
import TnCSection from "../sections/TnCSection";

function TermsAndCondtions(props) {
    return (
        <div className="privacy-policy">
        <Header/>
        <TnCSection/>
        <FooterSection/>
        </div>
    );
  }

  export default TermsAndCondtions;