import './PrivacyPolicySection.css';

import React from 'react';

function PrivacyPolicySection(props) {
    return (
        <div className="container priv-section">
        <p>
          Situs web <strong>www.ngebid.com</strong> dan aplikasi Ngebid dikelola oleh PT Ngebid
          Arunika Indonesia. Kebijakan Privasi ini adalah komitmen nyata dari
          Ngebid untuk menghargai dan melindungi data atau informasi pribadi
          pengguna situs ini.
        </p>
        <p>
          Kebijakan privasi ini menetapkan dasar atas perolehan, pengumpulan,
          penganalisisan, penampilan, pembukaan, dan/atau segala bentuk
          pengelolaan yang terkait dengan data atau informasi yang Pengguna
          berikan kepada Ngebid atau yang Ngebid kumpulkan dari Pengguna, termasuk
          data pribadi Pengguna, baik pada saat Pengguna melakukan pendaftaran di
          aplikasi, mengakses aplikasi, maupun mempergunakan layanan-layanan pada
          aplikasi Ngebid.
        </p>
        <p>
          PT Ngebid Arunika Indonesia tunduk terhadap kebijakan perlindungan data
          pribadi Pengguna sebagaimana yang diatur dalam Peraturan Menteri
          Komunikasi dan Informatika Nomor 20 Tahun 2016 Tentang Perlindungan Data
          Pribadi Dalam Sistem Elektronik yang mengatur dan melindungi penggunaan
          data dan informasi penting para Pengguna.
        </p>
        <p>
          Informasi mengenai identitas diri yang Kami peroleh dari Anda melalui
          aplikasi Ngebid adalah:
        </p>
        <p>&bull; Nama lengkap</p>
        <p>&bull; E-mail</p>
        <p>&bull; Nomor kontak</p>
        <p>&bull; Alamat lengkap</p>
        <p>&bull; No. Rekening</p>
        <p>
          Dengan melakukan pendaftaran/mengakses pada aplikasi Ngebid, Pengguna
          menyatakan bahwa setiap data Pengguna merupakan data yang benar dan sah,
          serta Pengguna memberikan persetujuan kepada Ngebid untuk memperoleh,
          mengumpulkan, menyimpan, mengelola dan mempergunakan data tersebut
          sebagaimana pengguna menggunakan seluruh layanan Ngebid. Kami
          berkomitmen untuk memastikan bahwa informasi yang Anda berikan kepada
          kami dalam keadaan aman.
        </p>
        <p>
          Ngebid melindungi segala hak pribadi yang muncul atas informasi mengenai
          suatu produk yang ditampilkan oleh pengguna Ngebid baik berupa foto,
          username, logo, dan lain-lain.
        </p>
        <p>
          Ngebid berhak menggunakan data dan informasi para Pengguna demi
          meningkatkan mutu dan pelayanan di aplikasi Ngebid.
        </p>
        <p>
          Ngebid tidak bertanggung jawab atas pertukaran data yang dilakukan
          sendiri di antara Pengguna.
        </p>
        <p>
          Ngebid hanya dapat memberitahukan data dan informasi yang dimiliki oleh
          para Pengguna bila diwajibkan dan/atau diminta oleh institusi yang
          berwenang berdasarkan ketentuan hukum yang berlaku, perintah resmi dari
          Pengadilan, dan/atau perintah resmi dari instansi atau aparat yang
          bersangkutan.
        </p>
        <p>
          Kami dapat mengubah Kebijakan Privasi ini dari waktu ke waktu dengan
          melakukan perubahan ataupun penambahan ketentuan. Anda diharapkan untuk
          memeriksa halaman Kebijakan Privasi secara berkala agar Anda mengetahui
          perubahan-perubahan tersebut. Dengan tetap mengakses dan menggunakan
          layanan pada <strong>aplikasi Ngebid</strong>, Anda dianggap telah menyetujui
          perubahan-perubahan ketentuan pada Kebijakan Privasi ini.
        </p>
      </div>
    );
}

export default PrivacyPolicySection;