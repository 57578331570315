import React from 'react';
import { NgebidLogoBlack } from '../assets';
import {NavLink} from 'react-router-dom';
import './style.css';

function Header(props, {history}) {

    return (
        <div className='header'>
<nav class="nav">
  <input type="checkbox" id="nav-check"/>
  <div class="nav-header">
    <div class="nav-title">
    <img className='ngebid-logo' src={NgebidLogoBlack} alt=""/>
    </div>
  </div>
  <div class="nav-btn">
    <label for="nav-check">
      <span></span>
      <span></span>
      <span></span>
    </label>
  </div>
  
  <ul class="nav-list head-menu">
  <NavLink
  to="/"
  className={({ isActive }) => isActive ? "active head-menu-item" : ""
  }
>
  Home
</NavLink>
<NavLink
  to="/privacy-policy"
  className={({ isActive }) => isActive ? "active head-menu-item" : ""
  }
>
  Privacy-Policy
</NavLink>
<NavLink
  to="/terms-and-conditions"
  className={({ isActive }) => isActive ? "active head-menu-item" : ""
  }
>
  Terms & Conditions
</NavLink>
  </ul>
</nav>
        </div>
    );
}

export default Header;