import Header from "../components/Header";
import FooterSection from "../sections/FooterSection";
import PrivacyPolicySection from "../sections/PrivacyPolicySection";

function Priv(props) {
    return (
        <div className="privacy-policy">
        <Header/>
        <PrivacyPolicySection/>
        <FooterSection/>
        </div>
       
            
    );
  }

  export default Priv;